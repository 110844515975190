export enum CookieName {
  Auth0Organization = '_karta_auth0_organization',
  QueryTracking = '_karta_query_tracking',
}

export enum PageType {
  OnboardingPage = 'ONBOARDING',
  CompanyBlockedPage = 'COMPANY_BLOCKED',
  CompanyWaitingDocumentsPage = 'COMPANY_WAITING_DOCUMENTS',
  CompanyUserBlockedPage = 'COMPANY_USER_BLOCKED',
  CompanyUserDeletedPage = 'COMPANY_USER_DELETED',
  CoreUserBlockedPage = 'CORE_USER_BLOCKED',
  ErrorPage = 'ERROR',
  MaintenancePage = 'MAINTENANCE',
  GetStartedPage = 'GET_STARTED',
}

export enum FilterType {
  Array = 'ARRAY',
  String = 'STRING',
}

export enum MediaAssetsFolder {
  ZeroStates = 'zero-states',
  GetStarted = 'get-started',
}

export enum NavigationGroup {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Soon = 'SOON',
}

export enum PermissionAction {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Destroy = 'destroy',

  // Допы
  ReadTransactionsReport = 'report',
  // transfer between
  CreateTransactionsInternal = 'internal',
  // send funds
  CreateTransactionsPayment = 'payment',
  ReadCardCredentials = 'credentials',
  CreateInvite = 'invite',
  CreateReinvite = 'reinvite',
  ReadBalances = 'balances',
  ReadKybLink = 'kybLink',
  CreateLinkToken = 'createLinkToken',
  CreateBudgetMember = 'createMember',
  UpdateBudgetUserToManager = 'updateToManager',
  UpdateBudgetUserToMember = 'updateToMember',
}

export enum SocketCmsListener {
  AppConfigCreateOrUpdate = 'app-config:createOrUpdate',
}

export enum NeuroIdSdkMethod {
  SetDebug = 'setDebug',
  Start = 'start',
  Identify = 'identify',
  ChangePage = 'stateChange',
  Submit = 'applicationSubmit',
  CloseSession = 'closeSession',
}

export enum DatePickerPresetName {
  LastThirtyDays = 'LAST_THIRTY_DAYS',
  Today = 'TODAY',
  LastSevenDays = 'LAST_SEVEN_DAYS',
  ThisWeek = 'THIS_WEEK',
  LastWeek = 'LAST_WEEK',
  ThisMonth = 'THIS_MONTH',
  LastMonth = 'LAST_MONTH',
  LastNinetyDays = 'LAST_NINETY_DAYS',
  ThisYear = 'THIS_YEAR',
  LastThreeHundredSixtyFiveDays = 'LAST_THREE_HUNDRED_SIXTY_FIVE_DAYS',
  LastYear = 'LAST_YEAR',
  AllTime = 'ALL_TIME',
}

export enum Currency {
  Usd = 'USD',
  Eur = 'EUR',
  Idr = 'IDR',
  Rub = 'RUB',
  Gbp = 'GBP',
}

export enum Layout {
  Default = 'DEFAULT',
  Empty = 'EMPTY',
}

export enum FilterDatePeriodName {
  LastThirtyDays = 'LAST_THIRTY_DAYS',
  Today = 'TODAY',
  ThisWeek = 'THIS_WEEK',
  LastWeek = 'LAST_WEEK',
  ThisMonth = 'THIS_MONTH',
  LastMonth = 'LAST_MONTH',
  ThisYear = 'THIS_YEAR',
  LastYear = 'LAST_YEAR',
  AllTime = 'ALL_TIME',
}

export enum SocialNetwork {
  WhatsApp = 'WHATSAPP',
  Telegram = 'TELEGRAM',
  Messenger = 'MESSENGER',
}
