import Axios from 'axios';

import type { Nullable } from '@karta.io/ui-components';

import { readCoreExchangeRate } from '@/api';

import { useEnv } from '@/composables';

import { BANNED_COUNTRIES } from '@/data';

import {
  CompanyProviderIncomePaymentMethod,
  CompanyProviderOnboardingStatus,
  CompanyProviderPaymentService,
  CompanyProviderStatus,
  CompanyUserRole,
  Currency,
} from '@/enums';
import type {
  CoreStoreState,
  CoreUserMeCompany,
  CoreUserMeCompanyProvider,
} from '@/interfaces';

const logger = useLogger('use-core-store');

export const useCoreStore = defineStore('coreStore', {
  state: (): CoreStoreState => ({
    isReadyForWork: false,
    isGlobalLoaderShow: true,
    companyUsers: [],
    companyUserId: undefined,
    firstName: '',
    lastName: '',
    fullName: '',
    avatar: '',
    email: '',
    requestedNewEmail: '',
    phone: '',
    orgId: '',
    id: undefined,
    permissions: null,
    phoneVerified: false,
    emailVerified: false,
    status: undefined,
    canCreateCompany: false,
    clickId: '',
    createdAt: '',
    phoneVerificationMethod: undefined,
    uiSettings: undefined,
    exchangeRate: [],
    authProviders: [],
    threeDsChannel: undefined,
    countryByIp: '',
  }),
  getters: {
    currentCompanyUser(state) {
      return (
        state.companyUsers?.find(
          companyUser => companyUser.id === state.companyUserId,
        ) ?? null
      );
    },
    currentCompany(): Nullable<CoreUserMeCompany> {
      return this.currentCompanyUser?.company || null;
    },
    currentCompanyCurrencies(): Currency[] {
      return this.currentCompany?.currencies || [];
    },
    companyByAuth0Id: state => (auth0Id: string) =>
      state.companyUsers?.find(
        companyUser => companyUser.company.auth0Id === auth0Id,
      )?.company,
    companyById: state => (id: number) =>
      state.companyUsers?.find(companyUser => companyUser.company.id === id)
        ?.company,
    isCurrentUser: state => (userId: CoreStoreState['id'] | undefined) =>
      state.id === userId,
    isCurrentUserOwner(): boolean {
      return this.currentCompanyUser?.role === CompanyUserRole.Owner;
    },
    isCurrentUserAdmin(): boolean {
      return this.currentCompanyUser?.role === CompanyUserRole.Admin;
    },
    isCurrentUserBookkeeper(): boolean {
      return this.currentCompanyUser?.role === CompanyUserRole.Bookkeeper;
    },
    isCurrentUserEmployee(): boolean {
      return this.currentCompanyUser?.role === CompanyUserRole.Employee;
    },
    hasExternalAccounts(): boolean {
      return this.currentCompany?.hasExternalAccounts ?? false;
    },
    hasCompanyAccounts(): boolean {
      return this.currentCompany?.hasCompanyAccounts ?? false;
    },
    isCompanyAccountFunded(): boolean {
      return (
        this.currentCompany?.providers?.some(
          item => item.isCompanyAccountFunded,
        ) ?? false
      );
    },
    /**
     * @todo story/kyb  isOnboardingLocked
     * Специальный feature флаг для тестирования kyb на проде
     * через блокировку на шаге Creating Business Profile
     * @see https://app.clickup.com/t/3863427/KR-20005
     * @see https://app.clickup.com/t/3863427/KR-20004
     */
    isOnboardingLocked(): boolean {
      return this.currentCompany?.isOnboardingLocked ?? false;
    },
    companyProviderByProviderId(): (
      providerId: number,
    ) => Nullable<CoreUserMeCompanyProvider> {
      return (providerId: number) =>
        this.currentCompany?.providers.find(
          provider => provider.providerId === providerId,
        ) ?? null;
    },
    companyProviders(): CoreUserMeCompanyProvider[] {
      return this.currentCompany?.providers ?? [];
    },
    companyProviderInOnboarding(): CoreUserMeCompanyProvider | undefined {
      return this.companyProviders?.find(
        provider =>
          provider.onboardingStatus !==
            CompanyProviderOnboardingStatus.Approved &&
          provider.status !== CompanyProviderStatus.Locked,
      );
    },
    companyProvidersActiveIds(): number[] {
      return (
        this.companyProviders
          .filter(provider => provider.status === CompanyProviderStatus.Active)
          .map(provider => provider.providerId) ?? []
      );
    },
    companyProvidersCryptoAvailableIds(): number[] {
      return (
        this.companyProviders
          .filter(
            provider =>
              provider.incomePaymentMethods?.includes(
                CompanyProviderIncomePaymentMethod.InboundCrypto,
              ) && provider.status === CompanyProviderStatus.Active,
          )
          .map(provider => provider.providerId) ?? []
      );
    },
    hasMarqetaPaymentService(): boolean {
      return (
        this.companyProviders.some(
          provider =>
            provider.paymentService === CompanyProviderPaymentService.Marqeta,
        ) ?? false
      );
    },
    queryKeyByCurrentCompanyId() {
      return (key: string) => `${key}_${this.currentCompany?.id}`;
    },
    isCountryBanned(): boolean {
      return this.countryByIp
        ? BANNED_COUNTRIES.includes(this.countryByIp)
        : false;
    },
  },
  actions: {
    async loadExchangeRates() {
      try {
        const results = await readCoreExchangeRate({
          fromCurrency: Currency.Usd,
        });
        this.exchangeRate = results ?? [];
      } catch (error) {
        logger.error('loadExchangeRate', error);
        return error;
      }
    },
    async loadIpCountryInfo() {
      const { ipInfoToken } = useEnv();
      if (!ipInfoToken) return;

      try {
        const response = await Axios.get(
          `https://ipinfo.io/country?token=${ipInfoToken}`,
        );

        this.countryByIp = response.data?.trim();
      } catch (error) {
        logger.error('loadIpCountryInfo', error);
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCoreStore, import.meta.hot));
}
