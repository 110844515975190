import { CryptoNetworksMap, CryptoTokensMap } from '@/data';

import { CryptoNetwork, CryptoToken, DepositCryptoStep } from '@/enums';
import type {
  BaseVerticalTab,
  CompanyAccount,
  CryptoWallet,
  DepositCryptoNetworkOption,
  DepositCryptoTokenOption,
} from '@/interfaces';

interface State {
  companyAccount?: CompanyAccount;
  networkOption?: DepositCryptoNetworkOption;
  tokenOption?: DepositCryptoTokenOption;

  step: DepositCryptoStep;
  isLoading: boolean;
}

export const useDepositCryptoStore = defineStore('depositCryptoStore', {
  state: (): State => ({
    companyAccount: undefined,
    networkOption: undefined,
    tokenOption: undefined,

    step: DepositCryptoStep.Choose,
    isLoading: false,
  }),
  getters: {
    cryptoWallet: (state: State): CryptoWallet | undefined => {
      if (!state.companyAccount || !state.networkOption || !state.tokenOption) {
        return;
      }

      return state.companyAccount.cryptoWallets?.find(
        wallet =>
          wallet.network === state.networkOption?.value &&
          wallet.tokenCode === state.tokenOption?.value,
      );
    },
    steps(state: State): BaseVerticalTab[] {
      return [
        {
          value: DepositCryptoStep.Choose,
          label: 'Deposit details',
          isDisabled: state.isLoading,
        },
        {
          value: DepositCryptoStep.Instruction,
          label: 'How to fund',
          isDisabled: state.isLoading || Boolean(!this.cryptoWallet),
        },
      ];
    },
    tokensOptions: (): DepositCryptoTokenOption[] => [
      {
        value: CryptoToken.Usdt,
        label: CryptoTokensMap[CryptoToken.Usdt].title,
        prefixIcon: CryptoTokensMap[CryptoToken.Usdt].icon,
      },
      {
        value: CryptoToken.Usdc,
        label: CryptoTokensMap[CryptoToken.Usdc].title,
        prefixIcon: CryptoTokensMap[CryptoToken.Usdc].icon,
      },
    ],
    networksOptions: (): DepositCryptoNetworkOption[] => [
      {
        value: CryptoNetwork.Trc20,
        label: CryptoNetworksMap[CryptoNetwork.Trc20].title,
        prefixIcon: CryptoNetworksMap[CryptoNetwork.Trc20].icon,
      },
      {
        value: CryptoNetwork.Erc20,
        label: CryptoNetworksMap[CryptoNetwork.Erc20].title,
        prefixIcon: CryptoNetworksMap[CryptoNetwork.Erc20].icon,
      },
    ],
  },
  actions: {
    setInitialTokenOption(value?: CryptoToken) {
      if (!value) {
        this.tokenOption = this.tokensOptions[0];
        return;
      }

      this.tokenOption =
        this.tokensOptions.find(option => option.value === value) ??
        this.tokensOptions[0];
    },
    setInitialNetworkOption(value?: CryptoNetwork) {
      if (!value) {
        this.networkOption = this.networksOptions[0];
        return;
      }

      this.networkOption =
        this.networksOptions.find(option => option.value === value) ??
        this.networksOptions[0];
    },
    addNewCryptoWallet(wallet: CryptoWallet) {
      this.companyAccount?.cryptoWallets?.push(wallet);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDepositCryptoStore, import.meta.hot),
  );
}
