import type { RouteRecordRaw } from 'vue-router';

import { PageType } from '@/enums';
import { useEnv } from '@/composables';
import {
  CoreUserPermissionsMap,
  ONBOARDING_ACTIVATE_PROFILE_PAGE,
  ONBOARDING_CREATE_COMPANY_PAGE,
  ONBOARDING_EMAIL_PAGE,
  ONBOARDING_GET_STARTED_PAGE,
  ONBOARDING_KYB_PAGE,
  ONBOARDING_KYC_PAGE,
  ONBOARDING_USER_DATA_PAGE,
} from '@/data';

const { isApp } = useEnv();

const defaultMeta = {
  auth: true,
  layout: 'empty',
  type: PageType.OnboardingPage,
};

const getStartedDefaultMeta = {
  auth: true,
  layout: 'empty',
  type: PageType.GetStartedPage,
};

const onboardingRoutes: RouteRecordRaw[] = [
  {
    path: '/onboarding',
    component: () =>
      import('@/modules/onboarding/pages/OnboardingIndexPage.vue'),
    meta: defaultMeta,
    children: [
      {
        path: 'user-data',
        name: ONBOARDING_USER_DATA_PAGE.name,
        component: () =>
          import('@/modules/onboarding/pages/OnboardingUserDataPage.vue'),
        meta: defaultMeta,
      },
      {
        path: 'email',
        name: ONBOARDING_EMAIL_PAGE.name,
        component: () =>
          import('@/modules/onboarding/pages/OnboardingEmailPage.vue'),
        meta: defaultMeta,
      },
      {
        path: 'company',
        component: {
          render() {
            return h(resolveComponent('router-view'));
          },
        },
        meta: defaultMeta,
        children: [
          {
            path: '',
            name: ONBOARDING_CREATE_COMPANY_PAGE.name,
            component: () =>
              import(
                '@/modules/onboarding/pages/OnboardingCreateCompanyPage.vue'
              ),
            meta: { ...defaultMeta },
          },
        ],
      },
      {
        path: 'activate-profile',
        name: ONBOARDING_ACTIVATE_PROFILE_PAGE.name,
        component: () =>
          import(
            '@/modules/onboarding/pages/OnboardingActivateProfilePage.vue'
          ),
        meta: defaultMeta,
      },
    ],
  },
  {
    path: '/get-started',
    name: ONBOARDING_GET_STARTED_PAGE.name,
    component: () =>
      import('@/modules/onboarding/pages/OnboardingGetStartedPage.vue'),
    meta: {
      auth: true,
      navigation: ONBOARDING_GET_STARTED_PAGE.navigation,
      permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
      layout: 'default',
      type: PageType.GetStartedPage,
    },
  },
  {
    path: '/get-started/kyb',
    name: ONBOARDING_KYB_PAGE.name,
    component: () => import('@/modules/onboarding/pages/OnboardingKybPage.vue'),
    meta: {
      ...getStartedDefaultMeta,
      permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
    },
  },
  {
    path: '/get-started/kyc',
    name: ONBOARDING_KYC_PAGE.name,
    component: () => import('@/modules/onboarding/pages/OnboardingKycPage.vue'),
    meta: {
      ...getStartedDefaultMeta,
      permissions: [CoreUserPermissionsMap.ReadCompanyAccount],
    },
  },
];

export default isApp ? onboardingRoutes : [];
