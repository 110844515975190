<script lang="ts" setup>
import { UiAvatarSize, UiTypography } from '@karta.io/ui-components';

interface Props {
  avatarSize?: UiAvatarSize;
}

defineOptions({ name: 'CompanyAccountSelectTriggerSkeleton' });

const props = withDefaults(defineProps<Props>(), {
  avatarSize: UiAvatarSize.Sm,
});

const cn = useClassName('company-account-select-trigger-skeleton');
</script>

<template>
  <div :class="cn.b()">
    <UiSkeletonAvatar :size="props.avatarSize" />

    <div>
      <UiSkeletonText :typography="UiTypography.TextSmMedium" width="60%" />
      <UiSkeletonText :typography="UiTypography.TextXsMedium" width="40%" />
    </div>
  </div>
</template>

<style lang="scss">
.company-account-select-trigger-skeleton {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--spacing-02);
}
</style>
