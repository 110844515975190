import qs from 'qs';
import { useCookies } from '@vueuse/integrations/useCookies';
import type { RouteLocationNormalized } from 'vue-router';

import { useCommonStore, useCoreStore, useTransactionsStore } from '@/stores';
import { readCoreAppConfig, readCoreUser } from '@/api';
import {
  CompanyStatus,
  CompanyUserStatus,
  CookieName,
  CoreLocalStorage,
  CoreSessionStorage,
  CoreUserStatus,
  PageType,
} from '@/enums';
import {
  CORE_COMPANY_BLOCKED_INDEX_PAGE,
  CORE_COMPANY_USER_BLOCKED_INDEX_PAGE,
  CORE_COMPANY_USER_DELETED_INDEX_PAGE,
  CORE_MAINTENANCE_INDEX_PAGE,
  CORE_ORGANIZATION_SELECTION_PAGE,
  CORE_ROOT_PAGE,
  CORE_USER_BLOCKED_INDEX_PAGE,
  ONBOARDING_CREATE_COMPANY_PAGE,
  ONBOARDING_EMAIL_PAGE,
  ONBOARDING_USER_DATA_PAGE,
} from '@/data';
import type { CoreLocalStorageUserStore, Page } from '@/interfaces';

type ReturnValue =
  | { name: Page['name']; query?: { returnToName: Page['name'] } }
  | undefined;

export function useRouterBaseChecks() {
  const userLocalStore = useStorage<CoreLocalStorageUserStore>(
    CoreLocalStorage.UserStore,
    {},
  );

  /**
   * @description Забираем userMe и наполняем стор.
   */
  const updateCoreUserStore = async () => {
    const { get } = useCookies();
    const coreStore = useCoreStore();

    const queryString = get(CookieName.QueryTracking);
    const params = queryString ? qs.parse(queryString) : {};

    const coreUser = await readCoreUser({ params });

    if (userLocalStore.value?.userId !== coreUser.id) {
      userLocalStore.value.userId = coreUser.id;
      userLocalStore.value.auth0Id = '';
    }

    coreStore.$patch({ ...coreUser });

    if (coreUser.companyUserId) {
      const currentCompanyUser = coreUser.companyUsers.find(
        item => item.id === coreUser.companyUserId,
      );
      const { uiSettings } = currentCompanyUser || {};
      if (!uiSettings || !Object.keys(uiSettings).length) return;

      coreStore.$patch({ uiSettings });
    }
  };

  /**
   * @description Забираем данные из cms, наполняем сторы, проверям на тех работы.
   * @param twk
   */
  const updateAppConfigStores = async (twk?: string): Promise<ReturnValue> => {
    const transactionsStore = useTransactionsStore();
    const commonStore = useCommonStore();

    const techWorksKeyStore = useStorage(
      CoreSessionStorage.TechWorksKey,
      '',
      sessionStorage,
    );

    const { data } = await readCoreAppConfig();

    transactionsStore.$patch({
      useDeclineReasonsMap: data.useTransactionDeclineReason,
      declineReasonsMap: data.transactionDeclineReason,
    });

    commonStore.$patch({
      getStartedSalesSocialsLinks: data.getStartedSalesSocialsLinks,
      contactUsLink: data.contactUsLink,
      showBillingPage: data.showBillingPage,
      techWorksKey: data.techWorksKey,
      isTechWorks: data.isTechWorks,
      isCompanyCreationTypeMq: data.isCompanyCreationTypeMq,
    });

    if (!data.isTechWorks) return;

    if (twk === data.techWorksKey) {
      techWorksKeyStore.value = twk;
    } else if (techWorksKeyStore.value !== data.techWorksKey) {
      return { name: CORE_MAINTENANCE_INDEX_PAGE.name };
    }
  };

  /**
   * @description Основные первые проверки статусов юзера и компании.
   */
  const checkUserStatuses = async (): Promise<ReturnValue> => {
    const coreStore = useCoreStore();
    /**
     * Проверка на верификацию почты
     */
    if (!coreStore.emailVerified) {
      return { name: ONBOARDING_EMAIL_PAGE.name };
    }

    /**
     * Проверка статуса нового пользователя (ведем на страницу имя/фамилия)
     */
    if (coreStore.status === CoreUserStatus.New) {
      return { name: ONBOARDING_USER_DATA_PAGE.name };
    }

    /**
     * Если глобальный пользователь заблокирован,
     * то вообще не даем доступ в интерфейс
     */
    if (coreStore.status === CoreUserStatus.Blocked) {
      return { name: CORE_USER_BLOCKED_INDEX_PAGE.name };
    }

    /**
     * Проверка статуса компани юзера, если заблокирован, то ведем на страницу блока
     */
    if (coreStore.currentCompanyUser?.status === CompanyUserStatus.Blocked) {
      return {
        name: CORE_COMPANY_USER_BLOCKED_INDEX_PAGE.name,
        query: { returnToName: CORE_ORGANIZATION_SELECTION_PAGE.name },
      };
    }

    /**
     * Если компани юзера вообще нет, то ведем на страницу с удаленным пользаком
     */
    if (coreStore.companyUserId && !coreStore.currentCompanyUser) {
      return {
        name: CORE_COMPANY_USER_DELETED_INDEX_PAGE.name,
        query: { returnToName: CORE_ORGANIZATION_SELECTION_PAGE.name },
      };
    }

    /**
     * Если компания пользователя заблокирована
     */
    if (
      coreStore.currentCompany?.status === CompanyStatus.Declined ||
      coreStore.currentCompany?.status === CompanyStatus.Blocked
    ) {
      return {
        name: CORE_COMPANY_BLOCKED_INDEX_PAGE.name,
        query: { returnToName: CORE_ORGANIZATION_SELECTION_PAGE.name },
      };
    }

    /**
     * @TODO story/organizations
     * Start: автологин
     */
    const companyUser = coreStore.companyUsers?.[0];

    /**
     * Если у юзера нет ниодной компании, ведем сразу на создание
     */
    if (!companyUser) {
      return { name: ONBOARDING_CREATE_COMPANY_PAGE.name };
    }

    /**
     * Механика автологина пользователя в последнюю залогиненую компанию
     */
    const recentlyUsedAuth0Id =
      userLocalStore.value?.userId === coreStore.id
        ? userLocalStore.value.auth0Id
        : null;

    const recentlyUsedCompany = recentlyUsedAuth0Id
      ? coreStore.companyByAuth0Id(recentlyUsedAuth0Id)
      : null;

    if (recentlyUsedCompany?.auth0Id) {
      return;
    }

    /**
     * Если у юзера есть только одна компания сразу логиним его туда
     */
    if (coreStore.companyUsers?.length === 1 && companyUser.company.auth0Id) {
      userLocalStore.value.userId = coreStore.id;
      userLocalStore.value.auth0Id = companyUser.company.auth0Id;

      await updateCoreUserStore();
      return;
    }

    /**
     * Если у пользователя много организаций и нет информации о последнем логине,
     * ведем на страницу выбора организации
     */
    if (coreStore.companyUsers!.length > 1 && !recentlyUsedCompany) {
      return { name: CORE_ORGANIZATION_SELECTION_PAGE.name };
    }

    /**
     * Finish: автологин
     */
  };

  /**
   * @param to
   * @description Проверяем на доступ по прямой ссылке на страницу
   * (закрытые роуты, попасть можно только через внутрений переход).
   */
  const checkPageAccess = (to?: RouteLocationNormalized): ReturnValue => {
    if (!to) return;

    switch (to.meta.type) {
      case PageType.CompanyBlockedPage:
      case PageType.CompanyWaitingDocumentsPage:
      case PageType.CompanyUserBlockedPage:
      case PageType.CompanyUserDeletedPage:
      case PageType.CoreUserBlockedPage:
      case PageType.ErrorPage:
      case PageType.MaintenancePage:
      case PageType.GetStartedPage:
      case PageType.OnboardingPage:
        return { name: CORE_ROOT_PAGE.name };
    }
  };

  return {
    updateCoreUserStore,
    updateAppConfigStores,
    checkUserStatuses,
    checkPageAccess,
  };
}
