<script lang="ts" setup>
import {
  UiAvatarSize,
  UiColor,
  UiColorVar,
  UiIconValue,
  UiTagSize,
  UiTagType,
  UiTypography,
} from '@karta.io/ui-components';

import {
  CompanyAccountStatusesMap,
  KARTA_PRIMARY_ACCOUNT_IMAGE,
  KARTA_SUB_ACCOUNT_IMAGE,
} from '@/data';

import { CompanyAccountStatus, CompanyAccountType } from '@/enums';
import type { CompanyAccount } from '@/interfaces';

interface Props {
  name: CompanyAccount['name'];
  type: CompanyAccount['type'];
  balance?: CompanyAccount['balance'];
  currency: CompanyAccount['currency'];
  status: CompanyAccount['status'];
  sourceAccount?: CompanyAccount['sourceAccount'];
  isDisabled?: boolean;
  isSelected?: boolean;
  isFocused?: boolean;
}

defineOptions({ name: 'CompanyAccountSelectOption' });

const props = withDefaults(defineProps<Props>(), {
  balance: undefined,
  sourceAccount: undefined,
  isDisabled: false,
  isSelected: false,
  isFocused: false,
});

const cn = useClassName('company-account-select-option');

const imageSrc = computed(() =>
  props.type === CompanyAccountType.Primary
    ? KARTA_PRIMARY_ACCOUNT_IMAGE
    : KARTA_SUB_ACCOUNT_IMAGE,
);

const balance = computed(() => formatMoney(props.balance, props.currency));

const isBlocked = computed(() =>
  [CompanyAccountStatus.Locked, CompanyAccountStatus.Closed].includes(
    props.status,
  ),
);
</script>

<template>
  <div
    :class="[
      cn.b(),
      cn.m('is-selected', props.isSelected),
      cn.m('is-focused', props.isFocused),
      cn.m('is-disabled', props.isDisabled),
    ]"
  >
    <UiAvatar :src="imageSrc" :size="UiAvatarSize.Sm" />

    <div>
      <div :class="cn.e('top-row')">
        <AppText
          tag="span"
          :typography="UiTypography.TextSmMedium"
          :color="UiColorVar.Secondary900"
          textEllipsis="100%"
        >
          {{ props.name }}
        </AppText>

        <UiTag
          v-if="isBlocked"
          :label="CompanyAccountStatusesMap[props.status].title ?? ''"
          :color="UiColor.Error"
          :type="UiTagType.Outline"
          :size="UiTagSize.Xs"
          hasTextEllipsis
        />

        <UiTag
          v-if="!isBlocked && props.sourceAccount"
          :label="props.sourceAccount.name"
          :color="UiColor.Secondary"
          :type="UiTagType.Outline"
          :size="UiTagSize.Xs"
          hasTextEllipsis
        />
      </div>
      <div :class="cn.e('bottom-row')">
        <AppText
          tag="div"
          :typography="UiTypography.TextXsMedium"
          :color="UiColorVar.Secondary500"
          textEllipsis="100%"
        >
          {{ balance }}
        </AppText>
      </div>
    </div>

    <div v-if="props.isSelected" :class="cn.e('icon')">
      <UiIcon
        :value="UiIconValue.Check"
        :color="UiColorVar.Primary600"
        size="20px"
      />
    </div>
  </div>
</template>

<style lang="scss">
.company-account-select-option {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: var(--spacing-02);
  padding: var(--spacing-03);
  border-radius: var(--border-radius-md);
  border: 1px solid transparent;

  &_is-disabled {
    background-color: var(--base-color-white);
    opacity: 0.4;
    cursor: not-allowed;
  }

  &_is-focused {
    background-color: var(--color-secondary-50);
  }

  &_is-selected {
    grid-template-columns: auto 1fr auto;
    background-color: var(--color-secondary-50);
  }

  &__top-row {
    display: grid;
    grid-template-columns: minmax(0, min-content) auto;
    align-items: center;
    gap: var(--spacing-02);
  }
}
</style>
